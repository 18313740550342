import React from "react"
import Header from "./header"
import Footer from "./footer"

const Layout = ({ location, title, children }) => {
  return (
    <div className="global-wrapper">
      <Header location={location} title={title} />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
