import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import Image from "gatsby-image"

const Header = props => {
  const location = props.location
  const title = props.title
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  const data = useStaticQuery(graphql`
    query avatarQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50, quality: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const renderAvatar = () => {
    const avatar = data?.avatar?.childImageSharp?.fixed

    return (
      <React.Fragment>
        {avatar && (
          <Image
            fixed={avatar}
            alt=''
            className="bio-avatar"
            imgStyle={{ borderRadius: `50%` }}
          />
        )}
      </React.Fragment>
    )
  }

  let header

  if (isRootPath) {
    header = (
      <h1 className="main-heading">
        {renderAvatar()} 
        <Link to="/">{title}</Link>
      </h1>
    )
  } else {
    header = (
      <Link className="header-link-home" to="/">
        {renderAvatar()} 
        {title}
      </Link>
    )
  }

  return (
    <header className="global-header">{header}</header>
  )
}

export default Header