import React from "react"
import Bio from "./bio"

const Footer = () => {
  return (
    <footer className="global-footer">
      <Bio />
    © Shawn Maust {new Date().getFullYear()}<br/> 
    Built with <a href="https://www.gatsbyjs.com">Gatsby</a>. 
    Hosted on <a href="https://www.netlify.com">Netlify</a>.
    </footer>
  )
}

export default Footer